import * as Sentry from "@sentry/nuxt";
import { z } from "zod";
import { useEventStore } from "~/stores/event";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const localePath = useLocalePath();

  const eventStore = useEventStore();
  eventStore.$reset();

  const accessedEventId = to.params.id;

  if (typeof accessedEventId !== "string") {
    return abortNavigation();
  }

  const eventIdSchema = z.string().uuid();
  const verifiedEventId = eventIdSchema.safeParse(accessedEventId);

  if (!verifiedEventId.success) {
    return abortNavigation();
  }

  const fetchEventResult = await useFetch(
    `/api/events/${verifiedEventId.data}`
  );
  const maybeError = fetchEventResult.error.value;
  if (maybeError !== null) {
    if (maybeError.statusCode === 404) {
      useToast().add({ title: "Cet évènement n'existe pas" });
    }
    return await navigateTo(localePath("/"));
  }

  eventStore.eventId = verifiedEventId.data;
  const supabase = useSupabaseClient();
  const userResponse = await supabase.auth.getUser();

  // Si l'utilisateur n'est pas connecté, redirige vers la page de connexion uniquement si nécessaire
  if (userResponse.data === null || userResponse.error !== null) {
    if (to.path !== localePath(`/events/${eventStore.eventId}/rejoindre`)) {
      return navigateTo(localePath(`/events/${eventStore.eventId}/rejoindre`));
    }
    return;
  }
  const user = userResponse.data.user;

  Sentry.setUser({ id: user.id });

  const { data, error } = await useFetch(
    `/api/events/${eventStore.eventId}/participants`,
    {
      query: {
        userId: user.id,
      },
    }
  );

  // Si l'utilisateur n'est pas dans les participants, redirige vers la page rejoindre
  if (error.value !== null || !data.value || data.value?.length === 0) {
    if (to.path !== localePath(`/events/${eventStore.eventId}/rejoindre`)) {
      return navigateTo(localePath(`/events/${eventStore.eventId}/rejoindre`));
    }
    return;
  }

  if (data.value && data.value.length > 0) {
    eventStore.participantId = data.value[0].id;
  }
});
